.bg-card1 {
  background: #fff;
}

.bg-card2 {
  background: linear-gradient(105deg, #207A96 5.03%, #47C5C4 96.33%);
}

.bg-card3 {
  background: linear-gradient(116deg, #641936 15.78%, #DA4C6E 60.22%, #FFD2B8 95.68%);
}