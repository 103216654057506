.hero-form-field[type="number"]::-webkit-outer-spin-button,
.hero-form-field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hero-form-field[type=number] {
  -moz-appearance: textfield;
}

.bg-hero {
  background: linear-gradient(360deg, #FFDADC 0.02%, #FFF8EE 100%, #FFF2EA 0.01%);
}

.marquee {
  position: relative;
}

.marquee::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  width: 203px;
  background: linear-gradient(-90deg, rgba(251, 244, 241, 0.00) 0%, #FBF4F1 100%);
  z-index: 2;
}

.marquee::after {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  bottom: 0;
  width: 203px;
  background: linear-gradient(90deg, rgba(251, 244, 241, 0.00) 0%, #FBF4F1 100%);
  z-index: 1;
}

.rfm-initial-child-container,
.rfm-marquee {
  min-width: 100%;
  justify-content: space-around;
}