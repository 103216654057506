.bg-sun {
  background: linear-gradient(225deg, #FFE1B3 12.78%, #FBB040 61.08%, #FB6240 87.51%);
}

.birds {
  position: absolute;
  width: 118px;
  height: 56px;
  pointer-events: none;
}

.water {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  height: 77vh;
  background: linear-gradient(180deg, #FFEFEE -7.88%, #40D1CC 23.98%, #10416F 69.65%);
  pointer-events: none;
}