@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

body {
  background: #FBF4F1;
  font-family: 'DM Sans', sans-serif;
}

.bg-gradient {
  background: linear-gradient(90.01deg, #522349 -0.2%, #DA4C6E 49.89%, #FFD6C0 99.99%);
}

.text-gradient {
  background: linear-gradient(93deg, #A04267 0%, #FFBAB8 98.85%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-darker {
  background: linear-gradient(92deg, #522349 5.09%, #DA4C6E 62.07%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.popover-shadow {
  box-shadow: 0px 13px 30px 0px #49374533;
}

.accordion-animation {
  overflow: hidden;
}

.accordion-animation[data-state='open'] {
  animation: slideDown 300ms ease-out;
}

.accordion-animation[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

.number-field[type="number"]::-webkit-outer-spin-button,
.number-field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-field[type=number] {
  -moz-appearance: textfield;
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}