.bg-dialog {
  background: linear-gradient(116deg, #641936 15.83%, #C55570 61.87%, #FFD2B8 98.61%);
}

.border-dialog::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #FFD2B8;
  border-radius: inherit;
  mask: linear-gradient(114.36deg, #FFFFFF 1.13%, rgba(255, 255, 255, 0.5) 1.14%, rgba(255, 255, 255, 0) 100%);
  -webkit-mask: linear-gradient(114.36deg, #FFFFFF 1.13%, rgba(255, 255, 255, 0.5) 1.14%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
}